module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"src/assets/images/app-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"96f445c933a573cf9464021c25ea9225"},
    },{
      plugin: require('../node_modules/gatsby-plugin-rollbar/gatsby-browser.js'),
      options: {"plugins":[],"accessToken":"ae9f48ff2f2946e2aed900aa8daea54a","captureUncaught":true,"captureUnhandledRejections":true,"payload":{"environment":"production"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"rum":{"applicationId":"3f38d4f4-16b6-4a2a-bd18-47c5beee9d0d","clientToken":"pub06a7567359d233018dba779e991d8eda","enabled":false,"site":"datadoghq.com","service":"ownly-admin","env":"staging","version":"2025.6","allowedTracingUrls":["https://api-stg.theownly.io/graphql"],"sessionSampleRate":100,"sessionReplaySampleRate":20,"trackUserInteractions":true,"trackResources":true,"trackLongTasks":true,"defaultPrivacyLevel":"mask-user-input"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
